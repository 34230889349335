<template>
    <section class="mb-12">
        <h3 class="mb-3 pb-1 border-b">
            {{ $t('calendar.calendar_view') }}
        </h3>
        <el-select v-model="calendarView" class="w-full" @change="changeCalendarView">
            <el-option :label="$t('calendar.month')" value="month" />
            <el-option :label="$t('calendar.week_per_employee_plus')" value="week_per_employee_plus" />
            <el-option :label="$t('calendar.timeline')" value="timeline" />
        </el-select>
    </section>
</template>
<script>
import Cookies from 'js-cookie';

export default {
    data() {
        return {
            calendarView: Cookies.get('calendar_view') !== undefined ? Cookies.get('calendar_view') : 'month',
        };
    },

    methods: {
        changeCalendarView() {
            Cookies.set('calendar_view', this.calendarView, { expires: 365 });
            this.$emit('calendarViewChanged', this.calendarView);
        },
    },
};
</script>
